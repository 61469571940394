import React from "react";
import Navbar from "./components/Navbar/Navbar";
import MobileNavbar from "./components/MobileNavbar/MobileNavbar";
import Badge from "./components/Badge/Badge";
import Projects from "./components/Projects/Projects";
import Experiences from "./components/Experiences/Experiences";
import Badges from "./components/Badges/Badges";
import Footer from "./components/Footer/Footer";
import "./App.css";
import CVDownload from "./components/CVDownload/CVDownload";
import BlogPosts from "./components/BlogPosts/BlogPosts";
import OpenBlog from "./components/OpenBlog/OpenBlog";

function App() {
  const params = new URLSearchParams(window.location.search);
  const loadOnly = params.get("loadonly");

  if (loadOnly) {
    return (
      <div className="App">
        {loadOnly === "blogposts" ? <BlogPosts /> : null}
        {loadOnly === "projects" ? <Projects /> : null}
        {loadOnly === "experiences" ? <Experiences /> : null}
        {loadOnly === "badges" ? <Badges /> : null}
        {loadOnly === "cvdownload" ? <CVDownload /> : null}
      </div>
    );
  } else {
    return (
      <div className="App">
        <Navbar />
        <MobileNavbar />
        <Badge />
        <Projects />
        <Experiences />
        <BlogPosts />
        {/* <Badges /> */}
        <Footer />
        <CVDownload />
        <OpenBlog />
      </div>
    );
  }
}

export default App;
