import React, { FC, useEffect } from "react";
import "./Badge.css";

interface BadgeProps {}

const Badge: FC<BadgeProps> = () => {
  return (
    <div className="Badge" data-testid="Badge">
      <div className="container BadgeContainer">
        <iframe
          title="Badge"
          src="https://badge.zfir.dev"
          className="container BadgeIframe"
        />
      </div>
    </div>
  );
};

export default Badge;
